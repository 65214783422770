import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import OrderDetailsContent from '../components/OrderDetails/OrderDetailsContent'
import api from '../api/credentials'
import { navigate } from 'gatsby'

const Finish = ({ location }) => {
    const [order, setOrder] = useState()

    const params = new URLSearchParams(location.search)
    const id = params.get('id')

    useEffect(() => {
        (async () => {
            try {
                setOrder(null)
                api(`${process.env.BACKEND_URL}/sanctum/csrf-cookie`).then(() => {
                    api(`${process.env.API_URL}/order?id=${id}`, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(data => {
                        setOrder(data.data)
                    }).catch(error => {
                        switch (error.response.status) {
                            case 401: // Not logged in
                            case 419: // Session expired

                                localStorage.removeItem('user')
                                window.dispatchEvent(new Event("removeUser"));
                                navigate('/signin')
                                break;
                           
                            default:
                                navigate('/404')
                        }
                       
                    })
                })
            } catch (error) {
                setOrder(null)
                navigate('/404')
            }
        })()
    }, [id])

    if (!order) return null

    return (
        <Layout title='Detalle de pedido'>
            <PageBanner />
            <OrderDetailsContent
                order={order}
            />
        </Layout>
    )
}
export default Finish