import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import api from "../../api/credentials";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@mui/material/Alert";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import { format } from "date-fns";
import { es } from "date-fns/locale";
const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
        />
    );
});

const OrderDetailsContent = ({ order }) => {
    const [error, setError] = useState(null);
    const [show, setShow] = useState(false);

    const formatter = new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "EUR",
    });

    const refundOrder = (order) => {
        if (window.confirm("¿Seguro que quieres tramitar la devolución?")) {
            api(`${process.env.BACKEND_URL}/sanctum/csrf-cookie`).then(() => {
                api.post(`${process.env.API_URL}/order/refund?order=${order}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((data) => {
                        navigate("/orders");
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 401: // Not logged in
                            case 419: // Session expired
                                localStorage.removeItem("user");
                                window.dispatchEvent(new Event("removeUser"));
                                navigate("/signin");
                                break;

                            default:
                                setError(error.response.data.error);
                        }
                    });
            });
        }
    };

    const handleClose = () => {
        setError(null);
    };

    const getStatus = (status) => {
        console.log(status);
        switch (status) {
            case "completed":
                return (
                    <>
                        {/* <button onClick={() => refundOrder(order.id)} className='btn-refund-order w-100'>
                            Devolver
                        </button> */}
                        <p className="badge rounded-pill bg-success">Completada</p>
                    </>
                );
            case "refunded":
                return <p className="badge rounded-pill bg-warning text-dark">Devuelto</p>;
            case "failed":
                return <p className="badge rounded-pill bg-danger">Compra fallida</p>;
            case "pending":
                return <p className="badge rounded-pill bg-secondary">Pendiente</p>;
            case "cancelled":
                return <p className="badge rounded-pill bg-danger">Cancelado</p>;
            default:
                return <p className="badge rounded-pill bg-dark">Estado desconocido</p>;
        }
    };
    const showImg = (img) => {
        if (!show) {
            var div = document.createElement("div");
            div.id = "darkbox";
            div.innerHTML = '<img class="darkboximg" src="' + img + '" />';
            document.body.appendChild(div);
            let box = document.getElementById("darkbox");

            box.addEventListener("click", (event) => {
                let element = document.getElementById("darkbox");
                element.parentNode.removeChild(element);

                setShow(false);
            });

            setShow(true);
        } else {
            // Remove it
            let element = document.getElementById("darkbox");
            element.parentNode.removeChild(element);

            setShow(false);
        }
    };
    const showBookingDate = (item) => {
        const pb = item.service.facility.permanent_bookings.find((element) => parseInt(element.id) === parseInt(item.permanent_booking_id));
        if (pb) {
            return format(new Date(pb.day), "dd/MM/yyyy", { locale: es }) + ` (${pb.start.split(":").slice(0, 2).join(":")}-${pb.end.split(":").slice(0, 2).join(":")})`;
        } else {
            return "No disponible";
        }
    };
    const showSeat = (meta) => {
        const seat = meta.seat;
        if (seat) {
            return `Fila: ${seat.row} - Butaca: ${seat.position}`;
        } else {
            return "No disponible";
        }
    };
    return (
        <section className="order-area">
            <div className="container">
                <div className="mb-5 row">
                    <div className="col-12">
                        <div className="orders-accordion">
                            <Accordion preExpanded={[order.id]}>
                                {order.items.map((item) => (
                                    <>
                                        <AccordionItem uuid={order.id}>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Nº de pedido: {order.locator}
                                                    <span className="float-end">Total: {formatter.format(order.total)}</span>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel className="p-3">
                                                <div className="row">
                                                    <div className="mt-3 col-md-9 col-12">
                                                        <div className="d-flex">
                                                            <div>
                                                                <Link to={`/service-details?slug=${item.service.slug}`}>{item.service.name}</Link>
                                                                <br />
                                                                <small className="text-muted">
                                                                    {formatter.format(item.price)} x {item.units} {item.units > 1 ? "unidades" : "unidad"}
                                                                </small>
                                                                {item.uses > 0 && (
                                                                    <>
                                                                        <br />
                                                                        <small className="text-muted">Usos restantes: {item.uses}</small>
                                                                    </>
                                                                )}
                                                                {item.zone_id && (
                                                                    <>
                                                                        <br />
                                                                        <small className="text-muted">Instalación: {item.service.facility?.name}</small>
                                                                        <br />
                                                                        <small className="text-muted">Zona: {item.service.facility?.zones.find((element) => parseInt(element.id) === parseInt(item.zone_id))?.name}</small>
                                                                    </>
                                                                )}
                                                                {item.permanent_booking_id && (
                                                                    <>
                                                                        <br />
                                                                        <small className="text-muted">Fecha: {showBookingDate(item)}</small>
                                                                    </>
                                                                )}
                                                                {item.meta.length > 0 && (
                                                                    <>
                                                                        {item.meta.map((mt) => (
                                                                            <span>
                                                                                <br />
                                                                                <small className="text-muted">{showSeat(mt)}</small>
                                                                            </span>
                                                                        ))}
                                                                    </>
                                                                )}
                                                                {item.booking && (
                                                                    <>
                                                                        <br />
                                                                        <strong>Datos de la reserva</strong>
                                                                        <br />
                                                                        <span>Inicio: {format(new Date(item.booking.starts_at), "dd/MM/yyyy HH:mm")}</span>
                                                                        <br />
                                                                        <span>Fin: {format(new Date(item.booking.ends_at), "dd/MM/yyyy HH:mm")}</span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-3 col-md-3 col-12 qrcode">
                                                        {item.status === "completed" && (
                                                            <>
                                                                <img
                                                                    src={`${process.env.BACKEND_URL}/qrcodes/${item.locator}.png`}
                                                                    alt={item.service.name}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="mt-5 row">
                                                    <div className="col-12 col-md-12 table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Estado</th>
                                                                    <th className="text-end">Fecha</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {item.statuses.map((status) => (
                                                                    <tr key={status.id}>
                                                                        <td>{getStatus(status.status)}</td>
                                                                        <td className="text-end">{format(new Date(status.created_at), "dd/MM/yyyy HH:mm")}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={error !== null}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {error}
                </Alert>
            </Snackbar>
        </section>
    );
};

export default OrderDetailsContent;
